.d-flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.align-items-center {
    align-items: center;
}
.primary-container {
    padding: 30px 0;
    width: 100%;
    padding: 0px 15px;
    margin-left: auto;
    margin-right: auto;
    line-height: 29px;
}
.primary-box {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
    max-width: 800px;
}
.shopify-icon-box {
    background-color: #1d44ea;
    height: 80px;
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title-new-trackier {
    margin-top: 30px;
    margin-bottom: 5px;
    text-align: left;
    color: #4332a5;
    font-weight: bolder;
    font-size: 20px;
}
.heading {
    text-align: left;
    font-size: 26px;
    font-weight: 800;
    margin: 10px 0px;
}
.desc {
    margin-bottom: 10px;
    color: #777;
    font-size: 15px;
    text-align: left;
    margin-left: 3px;
}
.request-demo {
    background-color: #3c51a7;
    color: #fff;
    border: none;
    margin-top: 20px;
    margin: 0 auto;
    margin-top: 63px;
    margin-bottom: 15px;
    border-radius: 0;
    font-size: 16px;
    display: block;
    height: max-content;
    text-decoration: none;
    padding: 10px;
    cursor: pointer;
    width: 300px;
    border-radius: 6px;
    &:disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    input {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
    .input-group-prepend {
        margin-right: -1px;
    }
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* // Input Group CSS */
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group-append {
    margin-left: -1px;
}

.input-group-append,
.input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
